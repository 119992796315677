import React, { useState, useEffect} from 'react'
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { height } from '../../config/window'

const styles = theme => ({
	container: {
		height: height*0.62,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		flexDirection: 'column'
	},
})

const ErrorContent = ({ classes }) => {
	const [msg, setMsg] = useState('Loading')
	const [timerDone, setTimerDone] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setMsg('Error getting data')
			setTimerDone(true)
		},2000)
		return () => {
			clearTimeout(timer)
		}
	},[])
	return (
		<Box className={classes.container}>
			<Box marginBottom='20px'>
			{timerDone ? <Typography variant='h3'><b>¯\_(ツ)_/¯</b></Typography> : <CircularProgress /> }
			</Box>
			<Typography>{msg}</Typography>
		</Box>
	)
}

export default withStyles(styles)(ErrorContent)