//src/layout/Navigation.js

import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
//import AuthScreen from '../../screens/AuthScreen'
import InitializingContent from '../InitializingContent'
import boosters from '../BoosterContent'
import GameScreen from '../../screens/GameScreen'
import LoginContent from '../LoginContent'
import { NavigationContext, BoosterDataContext, UserContext } from '../Hooks'
import { height } from '../../config/window'

const styles = theme => ({
	overlay: {
		position: 'absolute',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: height,
		width: '100%',
		maxWidth: theme.breakpoints.values.sm,
		//transitionDuration: '0.5s',
	}
})

const Navigation = ({ classes }) => {
	const { user, subdomainInfo, initializing } = useContext(UserContext)
	const { boosterData } = useContext(BoosterDataContext)
	
	const [showGameScreen, setShowGameScreen] = useState(false)
	const [activeBooster, setActiveBooster] = useState(null)
	const [location, navigateTo] = useState(null)	
	const navigationValue = { location, navigateTo }
	
	useEffect(() => {
		if (location === 'GameScreen'){ setShowGameScreen(true) }
	},[location])

	if (initializing){
		return <InitializingContent />
	} else if (boosterData && activeBooster == null){
		Object.values(boosterData).forEach(booster => {
			if (booster.active){ 
				let usersObj = booster.users ? booster.users : {}
				let users = Object.keys(usersObj)
				if(users.indexOf(user.uid) < 0){ 
					setActiveBooster(booster) 
				} else {
					if(!showGameScreen) { setShowGameScreen(true) }
				}
			}
		})
	} else if(activeBooster){
		const BoosterContent = boosters[activeBooster.name]
		return (<BoosterContent bizID={subdomainInfo.id} booster={activeBooster} user={user} />)
	} else if(user){
		if(!showGameScreen) { setShowGameScreen(true) }
	} else if (subdomainInfo == null){
		return (<Box>Error with link</Box> )
	}
	return (
		<NavigationContext.Provider value={navigationValue}>
			{!showGameScreen && <Box className={classes.overlay}><LoginContent subdomainInfo={subdomainInfo}/></Box>}
			<Box className={classes.overlay} display={showGameScreen ? null : 'none'}><GameScreen /></Box>
		</NavigationContext.Provider>
	)
}

export default withStyles(styles)(Navigation)