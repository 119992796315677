//src/components/BottomNav.js

import React, { useState, useContext } from 'react'
import { ContentContext } from '../Hooks'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SettingsIcon from '@material-ui/icons/Settings';

const BottomNav = () => {
	const [index, setIndex] = useState(0)
	const { setContent } = useContext(ContentContext)

	const handleOnChange = (e,i) => {
		setIndex(i)
		switch(i) {
			case 0:
				setContent('ChoiceContent')
				break
			case 1:
				setContent('LeaderboardContent')
				break
			case 2:
				setContent('SettingsContent')
				break
			default:
				setContent('ChoiceContent')
		}
	}
	
 	return (
 		<BottomNavigation
 			value={index}
 			onChange={(e,i)=> handleOnChange(e,i)}
 			style={{height:'100%'}}
 			showLabels
 		>
 			<BottomNavigationAction label="Music" icon={<MusicNoteIcon />}/>
 			<BottomNavigationAction label="Leaders" icon={<EqualizerIcon />}/>
 			<BottomNavigationAction label="Settings" icon={<SettingsIcon />}/>
 		</BottomNavigation>
 	)
}

export default BottomNav