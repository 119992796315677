import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey'
import lightBlue from '@material-ui/core/colors/lightBlue';
import indigo from '@material-ui/core/colors/lightBlue';
import CssBaseline from "@material-ui/core/CssBaseline"
import 'typeface-roboto';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
	palette: {
		primary: lightBlue,
		secondary: indigo,
		background: {
			default: grey[900]
		},
		text: {
			primary: '#FFFFFF'
		}
	}
})

ReactDOM.render(
	<ThemeProvider theme ={theme}>
		<CssBaseline />
		<App />
	</ThemeProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
