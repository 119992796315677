import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography'
import { UserContext } from '../Hooks'
import { height } from '../../config/window'

const styles = theme => ({
	container: {
		height: height*0.14,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		width: height*0.10,
		height: height*0.10,
		border: '2px solid white',
		borderRadius: '50%',
	}
})

const UserInfo = ({ classes, onEdit }) => {
	const { profile } = useContext(UserContext)
	
	const initials = profile.initials
	const color = profile.bgcolor
	return (
		<Box className={classes.container} onClick={onEdit}>
			<Box display='flex' flex={1} justifyContent='flex-end'><Typography variant='h5'><b>You:</b></Typography></Box>
			<Box display='flex' marginRight='15px' marginLeft='10px'><Avatar className={classes.avatar} style={{backgroundColor: color}}>{initials}</Avatar></Box>
			<Box display='flex' flex={1}><EditIcon /></Box>
		</Box>
	)
}
	
export default withStyles(styles)(UserInfo)