//src/layout/LeaderScreen.js

import React from 'react'
import { Box, Grow, Avatar, Button, Fab, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { height } from '../../config/window'
import UserInfo from './UserInfo'

const styles = theme => ({
	container: {
		height:height*0.60,
		width:'100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch'
	},
})

const Leaderboard = ({ classes }) => {
	return (
		<Box className={classes.container}>
		</Box>
	)
}

export default withStyles(styles)(Leaderboard)
