//src/components/TimeLeft.js

import React, { useState, useContext, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { DataContext } from '../Hooks'
import { height } from '../../config/window'
import '../../animation.css'

const styles = theme => ({
	timeLeftContainer:{
		width:'100%',
		height: height*0.07,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
})


const TimeLeft = ({ classes }) => {
	const { timeData } = useContext(DataContext)
	const [timerText, setTimerText] = useState('WAITING FOR ROUND')
	const [color, setColor] = useState('white')
	const [animation, setAnimation] = useState({})

	useEffect(() => {
			let voteTimeLeft = timeData.timeLeft - 30000
			let displayTime = voteTimeLeft
			if (voteTimeLeft <= 0){ displayTime = timeData.timeLeft }
			let timeLeft_minutes = parseInt((displayTime/(1000*60))%60)
			let timeLeft_seconds = parseInt((displayTime/1000)%60)
			let minutes = ('0' + timeLeft_minutes).substr(-1,1)
			let seconds = ('00' + timeLeft_seconds).substr(-2,2)
			let time = `${minutes}:${seconds}`
			if(voteTimeLeft > 0){ 
				 setTimerText('TIME LEFT ' + time)
				 if (voteTimeLeft <= 6000){ 
				 	if(animation.bounce == null){ 
				 		setAnimation({	
					 		...animation,
					 		bounce:{
						 		animationName: 'bounce',
						 		animationDuration: '1s',
						 		animationIterationCount: 5,
						 	}})
				 	}
				 	setColor('red') 
				 }
			} else if (timeData.timeLeft > 0){
				if (animation.bounce) { setAnimation({})}
				setColor('white')
				setTimerText('NEXT ROUND IN ' + time)
			} else {
				setTimerText('WAITING FOR ROUND')
			}
	},[timeData]) 

	return (
		<Box className={classes.timeLeftContainer}><Typography className={classes.text} style={{...animation.bounce, color:color}} variant='h5'><b>{timerText}</b></Typography></Box>
	)
}

export default withStyles(styles)(TimeLeft)