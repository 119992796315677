import React from 'react'

export const BoosterDataContext = React.createContext({
	boosterData:{},
	setBoosterData:()=>{},
})

export const UserContext = React.createContext({
	user: null,
	subdomainInfo: {},
	profile: {},
	initializing: true,
})

export const NavigationContext = React.createContext({
	location: 'LoginScreen',
	navigateTo: () => {},
})

export const ContentContext = React.createContext({
	contentName: 'ChoiceContent',
	setContent: () => {},
})

export const DataContext = React.createContext({
	game: {},
	leaderboard: {},
	whitelabel: {},
	timeLeft: 0,
})