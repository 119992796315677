//src/config/firebase.js

import * as fb from "firebase";

const FirebaseConfig = {
	apiKey: "AIzaSyC_FW72ZgmtO0VVg85DnNu4hVrezb3uPk4",
	authDomain: "fluid-unfolding-125308.firebaseapp.com",
	databaseURL: "https://fluid-unfolding-125308.firebaseio.com",
	projectId: "fluid-unfolding-125308",
    storageBucket: "fluid-unfolding-125308.appspot.com",
}

fb.initializeApp(FirebaseConfig);

const firebase = fb

export const authRef = fb.auth();
export const eventRef = fb.database().ref().child('parties')
export const gameRef = fb.database().ref().child('party-game-music-poll')
export const businessRef = fb.database().ref().child('business-assets')
export const routingRef = fb.database().ref().child('routing')

export default firebase