import React, { useState, useEffect} from 'react';
import firebase from './config/firebase'
import Container from '@material-ui/core/Container';
import Navigation from './components/Navigation'
import { UserContext, BoosterDataContext } from './components/Hooks'

const App = () => {
	const [user, setUser] = useState()
	const [initializing, setInitializing] = useState(true)
	const [timeInit, setTimeInit] = useState(false)
	const [userInit, setUserInit] = useState(false)
	const [subInit, setSubInit] = useState(false)
	const [boostInit, setBoostInit] = useState(false)
	const [profileInit, setProfileInit] = useState(false)
	const [subdomain, setSubdomain] = useState(null)
	const [subdomainInfo, setSubdomainInfo] = useState({})
	const [boosterData, setBoosterData] = useState()
	const [profile, setProfile] = useState({})


	const boosterValue = { boosterData, setBoosterData }

	useEffect(() => {
		setTimeout(() => setTimeInit(true),[1000])
		firebase.auth().onAuthStateChanged(user => {
			if (user){
				setUser(user)
			}
			console.log('setUserInit')
			setUserInit(true)
		})
	},[])	

	useEffect(()=>{
		let url = window.location.host //get from browser function
		let parts = url.split('.')
		let subdomain = 'testing' //parts[0] // get just the first part
		setSubdomain(subdomain)
	},[window.location])

	useEffect(() =>{
		setProfileInit(false)
		let uid = user ? user.uid : null
		let ref = firebase.database().ref('users/'+uid+'/info')
		if(uid){
			ref.on('value', snapshot => {
				if(snapshot && snapshot.val()){
					setProfile(snapshot.val())
				}
				setProfileInit(true)
			})
		} else {
			setProfileInit(true)
		}
	},[user])

	useEffect(() => {
		let ref = firebase.database().ref('subdomains/'+ subdomain)
		if (subdomain){
			console.log('subdomain')
			ref.on('value', snapshot => {
				if(snapshot && snapshot.val()){
					setSubdomainInfo(snapshot.val())
				}
				console.log('subInit')
				setSubInit(true)
			})
		}
		return () => {
			ref.off()
		}
	},[subdomain])

	useEffect(() =>{		
		if(userInit && subInit && boostInit && timeInit && profileInit){ 
			setInitializing(false)
		}
	},[userInit,subInit, boostInit, timeInit, profileInit])

	useEffect(() => {
		setBoostInit(false)
		let ref = firebase.database().ref('business-assets/' + subdomainInfo.id + '/integrations/boosters')
		if(user && subdomainInfo && subdomainInfo.id){			
			console.log('inboost')
			ref.on('value', snapshot => {
				if(snapshot && snapshot.val()){
					console.log(snapshot.val())
					setBoosterData(snapshot.val())
				}
				console.log('boostInit')
				setBoostInit(true)
			})
		} else {
			console.log('set boost true')
			setBoostInit(true)
		}
		return () => {
			ref.off()
		}
	},[user, subdomainInfo])

    return (
    	<UserContext.Provider value={{user:user, profile:profile, subdomainInfo:subdomainInfo, initializing:initializing}}>
    		<BoosterDataContext.Provider value={boosterValue}>
		        <Container maxWidth="sm" disableGutters={true}>
		            <Navigation />
		        </Container>
			</BoosterDataContext.Provider>
		</UserContext.Provider>
    )
}

export default App