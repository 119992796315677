import React, { useContext } from 'react'
import firebase from '../../config/firebase'
import { Box, Avatar, Fab, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { NavigationContext } from '../Hooks'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { height } from '../../config/window'

const styles = theme => ({
	allCentered:{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	container: {
		width: '100%',
		height: height,
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		padding:'10px',
	},
	avatar: {
		width: height*0.28,
		height: height*0.28,
		border: '5px solid ',
		borderRadius: '50%',
	}
})

const FacebookPageLike = ({ classes, bizID, booster, user }) => {
	const { navigateTo } = useContext(NavigationContext)

	const handleBackPress = () => {
		firebase.auth().signOut()
		.then(() => window.location.reload())
	}

	const handleLikeClick = () => {
		let body= {}
		body[user.uid] = true
		var windowRef = window.open()
		firebase.database().ref('/business-assets/'+bizID+'/integrations/boosters/facebookPageLike/users').update(body)
		.then(() => {
			windowRef.location = booster.pageUrl
		}).then(() => {
			navigateTo('GameScreen')
		})
	}

	return (
		<Box className={classes.container}>
			<Box alignSelf='flex-start'><Button onClick={handleBackPress}><ArrowBackIosIcon/>Sign Out</Button></Box>
			<Box className={classes.allCentered} marginBottom="20px"><Typography variant='h3'>Like</Typography></Box>
			<Box className={classes.allCentered}><Avatar className={classes.avatar} src={booster.imageUrl} /></Box>
			<Box className={classes.allCentered} margin='15px'><Typography variant='h4'><b>@{booster.handle}</b></Typography></Box>
			<Box textAlign='center'>
				<Box><Typography variant='h5'>on Facebook</Typography></Box>
				<Box><Typography variant='h5'>to access the music</Typography></Box>
			</Box>
			<Box marginTop='20px'display='flex' flex={1} flexDirection='column' justifyContent='flex-end' alignItems='center'>
				<Box style={{marginBottom:'20px'}}><Fab variant='extended' size='large' onClick={handleLikeClick}><ThumbUpIcon style={{marginRight:'8px'}}/>Like or Verify</Fab></Box>
				<Box><Typography>Return here after verification</Typography></Box>
			</Box>
		</Box>
	)
}

export default withStyles(styles)(FacebookPageLike)
