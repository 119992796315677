import React, { useContext, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { UserContext } from '../Hooks'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import BottomNav from './BottomNav'
import NowPlaying from './NowPlaying'
import sparkDJLogo from '../../assets/logo.png'
import ChoiceContent from '../ChoiceContent'
import LeaderboardContent from '../LeaderboardContent'
import SettingsContent from '../SettingsContent'
import { height } from '../../config/window'

const titles = {
	ChoiceContent: 'Choices',
	LeaderboardContent: 'Top DJs', 
	SettingsContent: 'Settings',
}

const styles = theme => ({
	mainContainer: {
		height: height,
		width: '100%',
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		overflow: 'hidden',
		backgroundColor: 'black',
	},
	headerLogo:{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: height*0.13,
		padding: '10px',
	},
	headerTitle:{
		height: height*0.07,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		borderTop: '2px solid white',
		borderBottom: '2px solid white'
	},
	overlay: {
		position: 'absolute',
		marginRight: 'auto',
		marginLeft: 'auto',
		top: height*0.20,
		height: height*0.60,
		width: '100%',
		maxWidth: theme.breakpoints.values.sm,
		transitionDuration: '0.5s',
	}
})

const Layout = ({ classes, children, contentName }) => {
	const { subdomainInfo } = useContext(UserContext)
	const logoSrc = subdomainInfo.logoUrl ? subdomainInfo.logoUrl : sparkDJLogo
	return (
		<Box className={classes.mainContainer}>
			<Box className={classes.headerLogo}><img src={logoSrc} /></Box>
			<Box className={classes.headerTitle}><Typography variant='h5'>{titles[contentName]}</Typography></Box>		
			<Box height={height*0.60}>
				<Box className={classes.overlay} display={contentName === 'ChoiceContent' ? null : 'none'} ><ChoiceContent /></Box>
				<Box className={classes.overlay} display={contentName === 'LeaderboardContent' ? null : 'none'} ><LeaderboardContent /></Box>
				<Box className={classes.overlay} display={contentName === 'SettingsContent' ? null : 'none'} ><SettingsContent /></Box>
			</Box>
			<NowPlaying />
			<Box height={height*0.10}><BottomNav /></Box>
		</Box>
	)
}

export default withStyles(styles)(Layout)