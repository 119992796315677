//src/layout/ChoiceScreen.js

import React, { useState, useContext, useEffect } from 'react'
import firebase from '../../config/firebase'
import { Backdrop, Box, Modal, Slide, Avatar, List, ListItem, ListItemText} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Choices from './Choices'
import TimeLeft from './TimeLeft'
import { height } from '../../config/window'

const styles = theme => ({
	container:{
		display: 'flex',
		height: height*0.60,
		width: '100%',
		alignItems: 'stretch',
		flexDirection: 'column',
	},	
	profileEditModal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		outline: 'none',
		height: height,
		maxWidth: '600px',
		alignSelf: 'center'
	},
})

const ChoiceContent = ({ classes }) => {
	const onSelect = () => {

	}

	return (
		<Box className={classes.container}>
			<TimeLeft />
			<Choices handleOnSelect={onSelect}/>
		</Box>
	)
}

export default withStyles(styles)(ChoiceContent)