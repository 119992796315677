import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import preloader from '../../assets/preloader.png'
import iconLogo from '../../assets/SparkDJ-Icon.png'
import { height } from '../../config/window'

const styles = theme => ({
	container: {
		height: height,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}
})

function InitializingContent({ classes }){
	return (
		<Box className={classes.container}>
			<Box width='75px' borderRadius='10px' overflow='hidden' marginBottom='30px'><img src={iconLogo} /></Box>
			<Box width='100px' marginBottom='10px'><img src={preloader} /></Box>
			<Box textAlign='center' paddingLeft='3px' paddingBottom='100px'><Typography variant='caption'>Loading</Typography></Box>
		</Box>
	)
}

export default withStyles(styles)(InitializingContent)