//src/layout/SettingsScreen.js

import React, { useState } from 'react'
import firebase from '../../config/firebase'
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../assets/builtWithSparkDJ.png'
import { height } from '../../config/window'

const settingsItems = [
	{text: 'Sign Out'},
	{text: 'Contact Spark DJ'},
	//{text: 'Acknowledgements'}
]

const styles = theme => ({
	container: {
		height: height * 0.60,
		width:'100%',
		backgroundColor: 'black',
		display:'flex',
		flexDirection: 'column',
		alignItems: 'stretch'
	},
	logoContainer: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		padding: '10px',
		alignItems: 'flex-end'
	}
})

const SettingsContent = ({ classes }) => {
	const [index, setIndex] = useState(null)

    const handleSelectedItem = (e,i) => {
    	console.log(i)
    	setIndex(i)
    	if (i === 0) { //SignOut
    		firebase.auth().signOut()
			.then(() => window.location.reload())
    	} else if (i === 1) {
    		window.open('mailto:contact@spark.dj')
    	} else if (i === 2) {

    	}
    }

	return (
		<Box className={classes.container}>
			<Box>
				{settingsItems.map((info,i) => {
		            return (
		            	<ListItem 
		            		key={i}
		            		style={{paddingLeft: "30px", borderBottom: '1px', borderStyle:"solid", backgroundColor:"black"}} 
		            		button
		            		selected={index === i}
		            		onClick={e=> {handleSelectedItem(e,i)}}
		            		ContainerProps={{key:info.text}}
		            	>
		            		<ListItemText primary={info.text}/>
		            	</ListItem>
		            )}
	        	)}
	        </Box>
        	<Box className={classes.logoContainer}><a href='https://www.sparkdj.com'><img src={logo}/></a></Box>
		</Box>
	)	
}




export default withStyles(styles)(SettingsContent)
