import React, { useState, useContext, useEffect } from 'react'
import firebase from '../../config/firebase'
import { withStyles } from '@material-ui/core/styles'
import { UserContext, BusinessDataContext, NavigationContext } from '../Hooks'
import InitializingContent from '../InitializingContent'
import BoosterContent from '../BoosterContent'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import { FacebookIcon, GmailIcon } from '../CustomIcons'
import logo from '../../assets/builtWithSparkDJ.png'
import { height } from '../../config/window'

const styles = theme => ({
	container: {
		height: height,
		width: '100%',
		display:'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'center',
		padding: '20px',
	}
})

function Content({ classes, subdomainInfo }) {
	
	const handleFacebookLoginOnClick = () => {
		var facebookProvider = new firebase.auth.FacebookAuthProvider()
		facebookProvider.addScope('email')
		firebase.auth().signInWithRedirect(facebookProvider)
	}

	const handleGmailLoginOnClick = () => {
		var gmailProvider = new firebase.auth.GoogleAuthProvider()
		firebase.auth().signInWithRedirect(gmailProvider)
	}
	
	const logoUrl = subdomainInfo.logoUrl
	return (
		<Box className={classes.container}>
			<Box display='flex' padding='25px' height={height*0.30} justifyContent='center' alignItems='center'><img style={{objectFit:'contain'}} src={logoUrl} /></Box>
			<Box display='flex' height={height*0.17} flexDirection='column' textAlign='center' alignItems='center' justifyContent='center'>
				<Typography variant='h4'>Vote on the music</Typography>
				<Typography variant='h4'>for <b>FREE!</b></Typography>
			</Box>
			<Box display='flex' height={height*0.20} justifyContent='center' alignItems='stretch' flexDirection='column'>
				<Box display='flex' justifyContent='center' marginBottom='20px'><Fab variant='extended' onClick={handleFacebookLoginOnClick}><FacebookIcon style={{marginRight:'8px'}}/>Sign in with Facebook</Fab></Box>
				<Box display='flex' justifyContent='center'><Fab variant='extended' onClick={handleGmailLoginOnClick}><GmailIcon style={{marginRight:'8px'}}/> Sign in  with Gmail</Fab></Box>
			</Box>
			<Box display='flex' flex={1} flexDirection='column' justifyContent='flex-end' alignItems='stretch'>
				<Box display='flex' justifyContent='center'>
					<a href='https://www.sparkdj.com'><img style={{objectFit:'contain'}} src={logo} /></a>
				</Box>	
				<Box display='flex' justifyContent='center'>
					<Typography variant='h6'><a href="https://www.sparkdj.com/privacy-policy">Privacy Policy</a></Typography>
				</Box>
			</Box>
		</Box>
	)	
}

export default withStyles(styles)(Content)