import React, { useState, useEffect, useContext } from 'react'
import firebase from '../config/firebase'
import { ContentContext, UserContext, NavigationContext, DataContext } from '../components/Hooks'
import InitializingContent from '../components/InitializingContent'
import Layout from '../components/Layout'
import ChoiceContent from '../components/ChoiceContent'
import LeaderboardContent from  '../components/LeaderboardContent'
import SettingsContent from '../components/SettingsContent'
import Box from '@material-ui/core/Box'
import ErrorContent from '../components/ErrorContent'

const parseDate = s => {
    var day, tz,
    rx=/^(\d{4}\-\d\d\-\d\d([tT ][\d:\.]*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/,
    p= rx.exec(s) || [];
    if(p[1]){
        day= p[1].split(/\D/);
        for(var i= 0, L= day.length; i<L; i++){
            day[i]= parseInt(day[i], 10) || 0;
        };
        day[1]-= 1;
        day= new Date(Date.UTC.apply(Date, day));
        if(!day.getDate()) return NaN;
        if(p[5]){
            tz= (parseInt(p[5], 10)*60);
            if(p[6]) tz+= parseInt(p[6], 10);
            if(p[4]== '+') tz*= -1;
            if(tz) day.setUTCMinutes(day.getUTCMinutes()+ tz);
        }
        return day;
    }
    return NaN;   
}

const GameScreen = () => {
	const { user, subdomainInfo } = useContext(UserContext)
	const { navigateTo } = useContext(NavigationContext)
	const [contentName, setContent] = useState('ChoiceContent')

	const [initializing, setInitializing] = useState(true)
	const [gameInit, setGameInit] = useState(false)
	const [whiteLabelInit, setWhiteLabelInit] = useState(false)
	const [leaderboardInit, setLeaderboardInit] = useState(false)

	const [gameData, setGameData] = useState({})
	const [leaderboardData, setLeaderboardData] = useState({})
	const [whiteLabelData, setWhiteLabelData] = useState({})
	const [timeData, setTimeData] = useState({})
	const [startTime, setStartTime] = useState(null)
	const [nowPlaying, setNowPlaying] = useState({})
	const [data, setData] = useState({})

	const contentValue = { contentName, setContent }

	useEffect(() => {
		let partyID = subdomainInfo.partyID
		let ref = firebase.database().ref('party-game-music-poll/' + partyID + '/rounds').orderByChild('roundStart').limitToLast(2)
		if(partyID){
			ref.on('value', snapshot => {
				if(snapshot && snapshot.val()){
					let data = snapshot.val()
					let roundIDs = Object.keys(data)
					data[roundIDs[1]].roundID = roundIDs[1]
					setGameData(data[roundIDs[1]])
					if (roundIDs.length > 0){
						let i = data[roundIDs[0]].winnerIndex
						setNowPlaying(data[roundIDs[0]].songs[i])
						let roundEnd = parseDate(data[roundIDs[0]].roundEnd)
						console.log(roundEnd.getTime())
						setStartTime(roundEnd.getTime() + 60000)
					}
				}
				setGameInit(true)
			})
		} else {
			setGameInit(true)
		}
		return () => {
			ref.off()
		}
	},[user])

	useEffect(() => {
		let id = subdomainInfo.id
		let ref = firebase.database().ref('business-assets/' + id + '/leaderboard')
		if(id){
			ref.on('value', snapshot => {
				if(snapshot && snapshot.val()){
					setLeaderboardData(snapshot.val())
				}
				setLeaderboardInit(true)
			})
		} else {
			setLeaderboardInit(true)
		}
		return () => {
			ref.off()
		}
	},[user])

	useEffect(() => {
		let id = subdomainInfo.id
		let ref = firebase.database().ref('business-assets/' + id + '/whitelabel')
		if(id){
			ref.on('value', snapshot => {
				if(snapshot && snapshot.val()){
					setWhiteLabelData(snapshot.val())
				}
				setWhiteLabelInit(true)
			})
		} else {
			setWhiteLabelInit(true)
		}
		return () => {
			ref.off()
		}
	},[])

	useEffect(() => {
		const timer = setInterval(()=>{
			let now = Date.now()
			let roundEnd = gameData.roundEnd ? parseDate(gameData.roundEnd) : null
			let timeLeft  = roundEnd ? roundEnd.getTime() - now + 60000: 0
			let progress = (roundEnd && startTime) ? (1-Math.max(timeLeft/(roundEnd-startTime+60000),0)) * 100 : 0
			let _timeData = { roundEnd:roundEnd, timeLeft:timeLeft,startTime: startTime, progress:progress }
			setTimeData(_timeData)
		},1000)
		return () => {
			clearInterval(timer)
		}
	},[gameData.roundEnd,startTime])

	useEffect(()=> {
		setData({game:gameData, leaderboard:leaderboardData, whitelabel:whiteLabelData, timeData:timeData, nowPlaying: nowPlaying})
	},[gameData, leaderboardData, whiteLabelData, timeData])

	useEffect(() => {
		if(gameInit && leaderboardInit && whiteLabelInit){
			setInitializing(false)
		}
	},[gameInit, leaderboardInit, whiteLabelInit])

	if(initializing){
		return (<InitializingContent />)
	} else if (user == null) {
		window.location.reload()
		return (<InitializingContent />)
	} else {
		return (
			<ContentContext.Provider value={contentValue}>
				<DataContext.Provider value={data}>
					<Layout contentName={contentName} />
				</DataContext.Provider>
			</ContentContext.Provider>
		)
	}
}

export default GameScreen