//src/components/TimeLeft.js

import React, { useState, useContext, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DataContext } from '../Hooks'
import { height } from '../../config/window'
import preloader from '../../assets/preloader.png'
import '../../animation.css'

const styles = theme => ({
	nowPlayingContainer:{
		width:'100%',
		height: height*0.10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'center',
		textAlign: 'center',
	},
})


const NowPlaying = ({ classes }) => {
	const { timeData, nowPlaying } = useContext(DataContext)
	const [nothingPlaying, setNothingPlaying] = useState(false)
	
	useEffect(() => {
		const buf = 10000 //10 seconds
		if (timeData.roundEnd) {
			if(timeData.roundEnd.getTime() + buf < Date.now()){
				setNothingPlaying(true)
			} else {
				setNothingPlaying(false)
			}
		}
	},[timeData])
	
	return (
		<Box className={classes.nowPlayingContainer}>
			<Box><LinearProgress variant="determinate" value={timeData.progress || 0} /></Box>
			<Box display='flex' alignItems='stretch' height='100%'>
				{!nothingPlaying && <img style={{objectFit:'contain'}} src={nowPlaying.coverArtURL} />}
				<Box flex={1} padding='10px' minWidth={0} display='flex' flexDirection='column' justifyContent='space-around'>
					<Box height={height*0.02} width='100%' display='flex' alignItems='center' justifyContent='center'>
						{!nothingPlaying && <img src={preloader} style={{objectFit:'contain', marginRight: '5px'}}/>}
						<Box><Typography>Now Playing</Typography></Box>
					</Box>
					{nothingPlaying ? <Typography><b>nothing playing . . .</b></Typography> : <Typography noWrap><b>{nowPlaying.name}</b> • {nowPlaying.artist}</Typography>}
				</Box>
			</Box>
		</Box>
	)
}

export default withStyles(styles)(NowPlaying)