
var _height = 0;

if( typeof( window.innerWidth ) == 'number' ) {
  //Non-IE
  _height = window.innerHeight;
} else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
  //IE 6+ in 'standards compliant mode'
  _height = document.documentElement.clientHeight;
} else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) ) {
  //IE 4 compatible
  _height = document.body.clientHeight;
}

export const height = _height
