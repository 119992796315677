import React, { useContext, useState, useEffect } from 'react'
import firebase from '../../config/firebase'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { DataContext, UserContext } from '../Hooks'	
import { height } from '../../config/window'

function toIsoString(aDate) {
    var tzo = -aDate.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return aDate.getFullYear() +
        '-' + pad(aDate.getMonth() + 1) +
        '-' + pad(aDate.getDate()) +
        'T' + pad(aDate.getHours()) +
        ':' + pad(aDate.getMinutes()) +
        ':' + pad(aDate.getSeconds()) +
        dif + pad(tzo / 60) +
        "" + pad(tzo % 60);
}

const styles = theme => ({
	allCentered:{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	container:{
		height: height*0.53,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems:' stretch',
	},
	choice:{	
		margin: '0px 10px 5px 10px',
		transitionDuration: '0.5s',
		borderTop: '2px solid lightgrey',
		borderBottom: '2px solid lightgrey',
		flex: 1,
	},
	innerChoice:{
		height: '100%',
		width:'100%',
		alignItems: 'stretch',
		display: 'flex',	
	},
	overlay: {
		position: 'absolute',
		marginRight: 'auto',
		marginLeft: 'auto',
		top: height*0.07,
		height: height*0.53,
		backgroundColor: 'rgba(0,0,0,0.7)',
		width: '100%',
		maxWidth: theme.breakpoints.values.sm,
		transitionDuration: '0.5s',
	}
})

const Choices = ({ classes, handleOnSelect}) => {
	const { user, subdomainInfo, profile } = useContext(UserContext)
	const { game, whitelabel, timeData } = useContext(DataContext)
	const getSel = () => {
		let _sel, songs = game.songs || [];
		songs.forEach((song,i) => {
			if (song.votes && song.votes[user.uid]){ 
				_sel = i
			}
		})
		return _sel
	}
	const [automation, setAutomation] = useState({})
	const [votesLocked, setVotesLocked] = useState(false)
	const [sel, setSel] = useState(()=>{ return getSel()})
	const [mounted, setMounted] = useState(false)

	useEffect(()=>{
		//Automation
		if (timeData.timeLeft <= 30000){
			setVotesLocked(true)
		} else {
			setVotesLocked(false)
		}
	},[game.roundEnd,timeData])

	useEffect(() => {
		if(mounted){
			setSel(null)
		} else {
			setMounted(true)
		}
	},[game.roundEnd])

	
	const handleOnChoicePress = (e,i) => {
		if(!votesLocked && sel == null){
			setSel(i)
			handleOnSelect()
			if(game.roundID){
				let body = {}
				body[user.uid] = {
					initials: profile.initials,
					initialsColor: profile.bgcolor ? profile.bgcolor.replace('#','') : null,
					bgcolor: profile.bgcolor,
					timestamp: toIsoString(new Date()),
					unixTimestamp: Date.now(),
					userID: user.uid,
					userName: profile.userName ? profile.userName: profile.initials,
					webVote: true,
				}
				firebase.database().ref('party-game-music-poll/'+subdomainInfo.partyID+'/rounds/'+game.roundID+'/songs/'+i+'/votes').update(body)
			}
		}
	}

	const choices = game.songs ? game.songs : []
	const colors = whitelabel.colors ? whitelabel.colors.choices : Array(5).fill({})
	return (
		<Box className={classes.container}>
			{votesLocked &&
				<Box className={`${classes.overlay} ${classes.allCentered}`} style={{opacity: 1.0}}>
					{(timeData.timeLeft != 0) && <Box style={{transform:'rotate(-30deg)'}}><Typography variant='h4'><b>VOTES ARE LOCKED</b></Typography></Box>}
				</Box>
			}
			{choices.map((info,i) => {
				let num = i + 1
				let color = colors[i]
				let opacity = 1.0
				if(sel != null && sel !=i){ 
					opacity = 0.3
				}
				return (
					<Box key={num} className={classes.choice} style={{opacity: opacity}}onClick={(e)=>{handleOnChoicePress(e,i)}}>
						<Box 
							className={classes.innerChoice}
							style={{background: `linear-gradient(to right,rgb(${color.left}),rgba(${color.right})`}}
						>
							<Box className={classes.allCentered} width={height*0.07}><Typography variant='h4'><b>{num}</b></Typography></Box>
							<Box className={classes.allCentered} width={height*0.07}><img style={{objectFit: 'contain'}} src={info.coverArtURL} /></Box>
							<Box display="flex" flex={1} minWidth={0} flexDirection="column" justifyContent="center" paddingLeft="15px" paddingRight='15px'>
								<Typography noWrap><b>{info.name}</b></Typography>
								<Typography noWrap>{info.artist}</Typography>
							</Box>
						</Box>
					</Box>
					
				)
			})}
		</Box>
	)
}

export default withStyles(styles)(Choices)